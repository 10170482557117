<template>
  <div class="widget widget-list">
    <div
      v-if="documentChecked && !documentSigned"
      class="clebex-widget-item empty-widget cursor-pointer"
      @click="signDocument"
    >
      <div>{{ displayLabelName("cockpit.widgets.not-signed") }}</div>
      <br />
      <div>{{ displayLabelName("cockpit.widgets.click-to-sign") }}</div>
    </div>
    <widget-content
      v-else-if="documentChecked && items && items.length"
      :items="items"
    >
    </widget-content>
    <div
      v-else-if="documentChecked"
      @click="
        $router.push({
          name: 'r_cockpit-settings-visitor-management'
        })
      "
      class="clebex-widget-item empty-widget cursor-pointer"
    >
      <icon class="icon" icon="#cx-app1-add" width="40" height="40" />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { mapActions, mapState } from "vuex";
import { APPLICATIONS } from "@/services/consts/application";

export default {
  name: "WidgetsVisitorManagement",
  components: {
    WidgetContent: defineAsyncComponent(() =>
      import("@/components/cockpit/widgets/visitor-management/Content")
    )
  },
  data() {
    return {
      documentChecked: false,
      documentSigned: false
    };
  },
  created() {
    this.getAcceptedByUser(APPLICATIONS.APP_VISITOR.id).then(response => {
      this.documentChecked = true;
      this.documentSigned = response;
      if (this.documentSigned) {
        this.getWidgetItems(this.data.type);
      }
    });
  },
  computed: {
    ...mapState("cockpit", ["widgetItems"]),
    items() {
      if (
        this.widgetItems[this.data.type] &&
        this.widgetItems[this.data.type].length
      ) {
        return this.widgetItems[this.data.type];
      }
      return null;
    }
  },
  methods: {
    ...mapActions("applications", ["getAcceptedByUser"]),
    ...mapActions("cockpit", ["getWidgetItems"]),
    signDocument() {
      this.$store.commit(
        "companyDocuments/setRedirectRoute",
        { to: this.$route, from: this.$route },
        {
          root: true
        }
      );
      this.$router.push({
        name: "r_company-documents-accept-documents",
        params: { applicationId: APPLICATIONS.APP_VISITOR.id }
      });
    }
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  }
};
</script>
